// @flow

/**
 * Export `showTawkTo`.
 */

export function showTawkTo() {
  if (!window || !window.Tawk_API) {
    return;
  }

  window.Tawk_API.maximize();
}
