// @flow

/**
 * Module dependencies.
 */

import type { TemplateProps } from 'types/template';
import { graphql, useStaticQuery } from 'gatsby';
import FAQsList from 'components/pricing/faqs-list';
import FeaturesSection from 'components/pricing/features-section';
import FooterIconsSection from 'components/footer/footer-icons-section';
import Header from 'components/pricing/header';
import PageContainer from 'components/page-container';
import React, { type Node, useMemo } from 'react';

/**
 * Pricing page query.
 */

const pricingPageQuery = graphql`
  query {
    data: allSeegnoCmsPricingPage {
      nodes {
        content {
          backgroundImage {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
          pricing {
            accent
            buttonLabel
            buttonUrl
            isTawkTo
            price {
              billedPriceFirstOption
              billedPriceFirstOptionLabel
              billedPriceSecondOption
              billedPriceSecondOptionLabel
            }
            salesLabel
            salesNumber
            title
          }
          features {
            title
            list {
              description
              id
              title
              icon {
                file {
                  publicURL
                }
              }
            }
          }
          footerSection {
            buttonLabel
            buttonUrl
            leftIcon {
              file {
                publicURL
              }
            }
            leftText
            rightIcon {
              file {
                publicURL
              }
            }
            rightText
          }
        }
      }
    }
  }
`;

/**
 * `Pricing` template.
 */

function Pricing({ pageContext }: TemplateProps): Node {
  const metatags = pageContext?.metatags;
  const content = pageContext?.content;
  const pricingData = useStaticQuery(pricingPageQuery);
  const dataContent = pricingData?.data?.nodes[0]?.content;
  const featuresList = useMemo(() => dataContent?.features?.list.map(
    item => ({ ...item, image: item?.icon?.file?.publicURL })
  ), [dataContent.features.list]);

  return (
    <PageContainer
      footerElement={<FooterIconsSection {...dataContent?.footerSection} />}
      metatags={metatags}
    >
      <Header
        backgroundFluidImage={dataContent?.backgroundImage?.file?.childImageSharp?.fluid}
        description={content?.description}
        pricing={dataContent?.pricing}
        title={content?.title}
      />

      <FeaturesSection
        items={featuresList}
        title={dataContent?.features?.title}
      />

      <FAQsList
        items={content?.faqs?.list}
        title={content?.faqs?.title}
      />
    </PageContainer>
  );
}

/**
 * Export `Pricing` template
 */

export default Pricing;
