// @flow

/**
 * Module dependencies.
 */

import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { isEmpty, map } from 'lodash';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * Export `FeaturesListProps` type.
 */

export type FeaturesListProps = {|
  items: Array<{
    description?: string,
    image?: string,
    title: string
  }>
|};

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-gap: ${units(11)} ${units(11)};
  justify-content: center;
  text-align: center;

  ${media.min('xs')`
    grid-template-columns: ${units(41)};
  `}

  ${media.min('md')`
    grid-template-columns: repeat(2, ${units(41)});
  `}

  ${media.min('xl')`
    grid-template-columns: repeat(3, ${units(41)});
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled.img`
  height: ${units(7)};
  margin: 0 auto ${units(3)};
  width: ${units(7)};
`;

/**
 * `FeaturesList` component.
 */

function FeaturesList({ items }: FeaturesListProps): Node {
  return !isEmpty(items) && (
    <Grid>
      {map(items, ({ description, id, image, title }) => (
        <div key={id}>
          {image && <StyledImage src={image} />}

          <Type.H4
            color={color('secondary')}
            marginBottom={units(1)}
          >
            {title}
          </Type.H4>

          {description && (
            <Type.Label
              as={'div'}
              color={color('grey800')}
            >
              <RawHtml>
                {description}
              </RawHtml>
            </Type.Label>
          )}
        </div>
      ))}
    </Grid>
  );
}

/**
 * Export `FeaturesList` component.
 */

export default FeaturesList;
