// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Fill, Grid } from 'components/core/layout';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { map } from 'lodash';
import BackgroundImage from 'components/core/images/background-image';
import PricingCard, { type PricingType } from 'components/pricing-card';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  backgroundFluidImage?: Object,
  description: ?string,
  pricing?: ?Array<PricingType>,
  title: ?string
|};

/**
 * `HeaderSection` styled component.
 */

const HeaderSection = styled.header`
  min-height: ${units(84)};
  padding-top: ${units(26)};
  position: relative;
`;

/**
 * `CardsGrid` styled component.
 */

const CardsGrid = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: ${units(-2.5)};

  ${media.min('md')`
    flex-wrap: nowrap;
    margin: ${units(-2)};
  `}

  ${media.min('xl')`
    margin: ${units(-5)};
  `}
`;

/**
 * `CardsGridItem` styled component.
 */

const CardsGridItem = styled.li`
  flex: 1 0 auto;
  margin: ${units(2.5)};

  ${media.min('md')`
    margin: ${units(2)};
  `}

  ${media.min('xl')`
    margin: ${units(5)};
  `}
`;

/**
 * `BackgroundWrapper` styled component.
 */

const BackgroundWrapper = styled(Fill)`
  bottom: 200px;
`;

/**
 * `StyledBackgroundImage` styled component.
 */

const StyledBackgroundImage = styled(BackgroundImage)`
  &,
  &::after,
  &::before {
    background-position: bottom center !important;
  }
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  padding-bottom: ${units(18)};
`;

/**
 * `Header` component.
 */

function Header(props: Props): Node {
  const { backgroundFluidImage, description, pricing, title } = props;

  return (
    <HeaderSection>
      <BackgroundWrapper>
        <StyledBackgroundImage fluid={backgroundFluidImage} />
      </BackgroundWrapper>

      <StyledContainer>
        {title && (
          <Type.H1
            color={color('secondary')}
            marginBottom={units(2)}
            textAlign={'center'}
          >
            {title}
          </Type.H1>
        )}

        {description && (
          <Type.H5
            color={color('secondary')}
            marginBottom={units(6)}
            marginBottomMd={units(9.5)}
            textAlign={'center'}
          >
            <RawHtml>
              {description}
            </RawHtml>
          </Type.H5>
        )}

        <Grid>
          <Box
            gridColumn={'1 / -1'}
            gridColumnLg={'2 / -2'}
          >
            <CardsGrid>
              {map(pricing, (item, index: number) => (
                <CardsGridItem key={index}>
                  <PricingCard
                    {...item}
                    numbersLabel={item.salesLabel}
                    numbersValue={item.salesNumber}
                  />
                </CardsGridItem>
              ))}
            </CardsGrid>
          </Box>
        </Grid>
      </StyledContainer>
    </HeaderSection>
  );
}

/**
 * Export `Header` component.
 */

export default Header;
