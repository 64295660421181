// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Grid } from 'components/core/layout';
import { Type } from '@seegno-labs/react-components/typography';
import { color, units } from '@seegno-labs/react-components/styles';
import { isEmpty } from 'lodash';
import FeaturesList, { type FeaturesListProps } from 'components/features-list';
import React, { type Node } from 'react';

/**
 * `Props` type.
 */

type Props = {|
  ...FeaturesListProps,
  title: string
|};

/**
 * `FeaturesSection` component.
 */

function FeaturesSection({ items, title }: Props): Node {
  return !isEmpty(items) && (
    <Container>
      <Grid>
        <Box
          gridColumn={'1 / -1'}
          gridColumnSm={'2 / -2'}
          marginBottom={units(14)}
          marginBottomLg={units(20)}
        >
          <Type.H2
            color={color('secondary')}
            marginBottom={units(8)}
            textAlign={'center'}
          >
            {title}
          </Type.H2>

          <FeaturesList items={items} />
        </Box>
      </Grid>
    </Container>
  );
}

/**
 * Export `FeaturesSection` component.
 */

export default FeaturesSection;
