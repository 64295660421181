// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Grid } from 'components/core/layout';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { isEmpty, map } from 'lodash';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  items: Array<{
    description: string,
    id: string,
    title: string
  }>,
  title: string
|};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  background-color: ${color('grey100')};
  padding: ${units(10)} 0;

  ${media.min('ms')`
    padding: ${units(20)} 0;
  `}
`;

/**
 * `ItemsGrid` styled component.
 */

const ItemsGrid = styled.ul`
  display: grid;
  grid-gap: ${units(5)} ${units(7)};

  ${media.min('ms')`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `FAQsList` component.
 */

function FAQsList({ items, title }: Props): Node {
  return !isEmpty(items) && (
    <Section>
      <Container>
        <Grid>
          <Box
            gridColumn={'1 / -1'}
            gridColumnMd={'3 / -3'}
          >
            <Type.H2
              color={color('secondary')}
              marginBottom={units(4)}
              marginBottomLg={units(12)}
              marginBottomMd={units(8)}
              textAlign={'center'}
            >
              {title}
            </Type.H2>

            <ItemsGrid>
              {map(items, ({ description, id, title }) => (
                <li key={id}>
                  <Type.Label
                    as={'p'}
                    color={color('secondary')}
                    fontWeight={700}
                    marginBottom={units(2)}
                  >
                    {title}
                  </Type.Label>

                  {description && (
                    <Type.Label
                      as={'div'}
                      color={color('secondary')}
                    >
                      <RawHtml>
                        {description}
                      </RawHtml>
                    </Type.Label>
                  )}
                </li>
              ))}
            </ItemsGrid>
          </Box>
        </Grid>
      </Container>
    </Section>
  );
}

/**
 * Export `FAQsList` component.
 */

export default FAQsList;
