// @flow

/**
 * Module dependencies.
 */

import { Type } from '@seegno-labs/react-components/typography';
import { color, units } from '@seegno-labs/react-components/styles';
import { showTawkTo } from 'utils/tawk-to';
import { switchProp } from 'styled-tools';
import Button from 'slyk-design-system/button';
import React, { type Node } from 'react';
import regex from 'utils/regex';
import styled, { css } from 'styled-components';
import useTranslate from 'hooks/use-translate';

/**
 * Button themes.
 */

const buttonThemes = {
  darkBlue: 'primary',
  green: 'green',
  primary: 'primary'
};

/**
 * Export `PricingType` type.
 */

export type PricingType = {|
  accent?: 'darkBlue' | 'green' | 'primary',
  buttonLabel: string,
  buttonUrl: string,
  className?: string,
  hasScale?: boolean,
  icon: {
    file: {
      publicURL: string
    }
  },
  isTawkTo?: boolean,
  numbersLabel: string,
  numbersValue: string,
  price: {|
    billedPriceFirstOption: string,
    billedPriceFirstOptionLabel?: string,
    billedPriceSecondOption?: string,
    billedPriceSecondOptionLabel?: string
  |},
  title: string
|};

/**
 * `Card` styled component.
 */

const Card = styled.div`
  background-color: ${color('white')};
  border-radius: ${units(1)};
  box-shadow: 0 0 25px 0 ${color.transparentize('black', 0.05)}, 0 5px 5px 1px ${color.transparentize('black', 0.05)};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${units(42)};
  overflow: hidden;
  position: relative;
  width: 100%;
`;

/**
 * `CardHeader` styled component.
 */

const CardHeader = styled.div`
  align-items: center;
  color: ${color('white')};
  display: flex;
  flex-direction: column;
  padding: ${units(3)} ${units(3)} ${units(4)};
  row-gap: ${units(2)};

  ${switchProp('colorTheme', {
    darkBlue: css`
      background-color: ${color('blue700')};
    `,
    green: css`
      background-color: ${color('green500')};
    `,
    primary: css`
      background-color: ${color('primary')};
    `
  })}
`;

/**
 * `CardContent` styled component.
 */

const CardContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: ${units(3)} ${units(4)} ${units(4)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H4).attrs({ as: 'p' })`
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
`;

/**
 * `PricesWrapper` styled component.
 */

const PricesWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

/**
 * `PriceWrapper` styled component.
 */

const PriceWrapper = styled.div`
  align-items: center;
  column-gap: ${units(0.25)};
  display: flex;
`;

/**
 * `MonthPriceWrapper` styled component.
 */

const MonthPriceWrapper = styled.div`
  align-items: center;
  column-gap: ${units(0.5)};
  display: flex;
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Label).attrs({ as: 'p' })`
  color: ${color('grey500')};
  margin-bottom: ${units(3)};
`;

/**
 * Get button props.
 */

function getButtonProps({ buttonUrl, isTawkTo, showTawkTo }) {
  if (isTawkTo) {
    return { onClick: showTawkTo };
  }

  if (buttonUrl.match(/^https?:\/\//)) {
    return { href: buttonUrl, rel: 'noopener', target: '_blank' };
  }

  return { to: buttonUrl };
}

/**
 * `PricingCard` component.
 */

function PricingCard(props: PricingType): Node {
  const translate = useTranslate();
  const {
    accent = 'primary',
    buttonLabel,
    buttonUrl,
    className,
    isTawkTo,
    numbersLabel,
    numbersValue,
    price,
    title
  } = props;

  const {
    billedPriceFirstOption,
    billedPriceFirstOptionLabel,
    billedPriceSecondOption,
    billedPriceSecondOptionLabel
  } = price;

  const billedYearlyPriceIsNumber = regex.hasNumbers.test(billedPriceFirstOption);
  const hasExtraPadding = !billedYearlyPriceIsNumber && !billedPriceSecondOption;

  return (
    <Card className={className}>
      <CardHeader colorTheme={accent}>
        <Title>
          {title}
        </Title>

        <PricesWrapper>
          {billedPriceFirstOption && (
            <PriceWrapper>
              <Type.H3
                as={'p'}
                padding={hasExtraPadding ? '11px 0' : 0}
              >
                {billedPriceFirstOption}
              </Type.H3>

              {billedYearlyPriceIsNumber && (
                <>
                  <Type.H4
                    as={'p'}
                    paddingTop={'0.06em'}
                  >
                    {translate('pricing.priceScale')}
                  </Type.H4>

                  {billedPriceFirstOptionLabel && (
                    <Type.Label
                      as={'p'}
                      marginLeft={units(0.25)}
                      paddingTop={'0.5em'}
                    >
                      {billedPriceFirstOptionLabel}
                    </Type.Label>
                  )}
                </>
              )}
            </PriceWrapper>
          )}

          {billedPriceSecondOption && (
            <MonthPriceWrapper>
              {regex.hasNumbers.test(billedPriceSecondOption) ? (
                <>
                  <Type.Label as={'p'}>
                    {`${billedPriceSecondOption}${translate('pricing.priceScale')}`}
                  </Type.Label>

                  {billedPriceSecondOptionLabel && (
                    <Type.Paragraph paddingTop={'0.1em'}>
                      {billedPriceSecondOptionLabel}
                    </Type.Paragraph>
                  )}
                </>
              ) : (
                <Type.Paragraph>
                  {billedPriceSecondOption}
                </Type.Paragraph>
              )}
            </MonthPriceWrapper>
          )}
        </PricesWrapper>
      </CardHeader>

      <CardContent>
        <Type.H4
          color={color('secondary')}
          fontWeight={600}
          marginBottom={units(0.25)}
        >
          {numbersValue}
        </Type.H4>

        <Label>
          {numbersLabel}
        </Label>

        {buttonLabel && (buttonUrl || isTawkTo) && (
          <Button
            colorTheme={buttonThemes[accent]}
            fullWidth
            size={'medium'}
            {...getButtonProps({ buttonUrl, isTawkTo, showTawkTo })}
          >
            {buttonLabel}
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

/**
 * Export `PricingCard` component.
 */

export default PricingCard;
